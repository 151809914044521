import MedicalSpeciality from '@/statics/medicalSpeciality';
import Range from '@/statics/range';
import Trolley from '@/statics/trolley';
import { Feature, GIProcedureArea, LowerGILength, RespiratoryType } from '@/statics/scopeFilter';
import { LocaleCode } from '@/statics/locale';
import { ProcedureType, ProcedureTypeSelectOptions } from '@/statics/procedureType';

export default {
  meta: {
    /* same as meta infos in index_ru.html */
    title: 'Эндоскопическая система — Настройка конфигурации в 5 шагов',
    description:
      'Выберите основной тип проводимых вами процедур, подберите компоненты, отвечающие вашим потребностям и получите обзор вашей индивидуальной конфигурации эндоскопической стойки по электронной почте.'
  },
  locale: {
    [LocaleCode.DE]: 'Немецкий',
    [LocaleCode.EN]: 'Английский США',
    [LocaleCode.EN_GB]: 'Английский Великобритания',
    [LocaleCode.ES]: 'Испанский',
    [LocaleCode.IT]: 'Итальянский',
    [LocaleCode.NL]: 'Голландский',
    [LocaleCode.RU]: 'Русский'
  },
  step: 'Шаг',
  progressBarTitles: {
    gettingStarted: '@:gettingStarted.title',
    procedures: '@:procedures.title',
    optimize: '@:optimize.title',
    accessories: '@:accessories.title',
    request: '@:request.title'
  },
  loadingConfiguration: 'Загрузка конфигурации...',
  gettingStarted: {
    title: 'Начало работы',
    startScreenHeadline: 'Настройте эндоскопическую систему в несколько простых шагов.',
    medicalSpecialityHeadline: 'Выберите вашу медицинскую специальность',
    medicalSpeciality: {
      [MedicalSpeciality.GASTROENTEROLOGY]: 'Гастроэнтерология',
      [MedicalSpeciality.PULMONOLOGY]: 'Пульмонология'
    },
    trolleyProcedureTypesHeadline: 'Выберите основную направленность проводимых вами процедур',
    procedureTypeSelectOptions: {
      [ProcedureTypeSelectOptions.ADVANCED]: 'Сложные процедуры и операции',
      [ProcedureTypeSelectOptions.GENERAL]: 'Базовые процедуры'
    }
  },
  procedures: {
    title: 'Процедуры',
    headline: 'Выберите тип процедур и предпочтительные особенности оборудования.',
    procedureRequest:
      'Для проведения каких процедур вы планируете инвестировать в новое оборудование?',
    procedureTypes: {
      [ProcedureType.DIAGNOSTIC]: 'Базовые процедуры',
      [ProcedureType.THERAPEUTIC]: 'Сложные процедуры и операции'
    },
    errorNoProcedureSelected: 'Выберите одну из процедур из списка ниже.',
    resetFilter: 'Сбросить выбор',
    hasRequirementsInfo: 'Выберите не менее одной процедуры.',
    isRequirementInfo: 'Удалите выбранные процедуры:',
    rangeRequest: 'Выберите, что для вас важнее при приобретении оборудования',
    range: {
      [Range.BASIC]: 'Лучшая цена',
      [Range.MID_RANGE]: 'Баланс "цена - качество"',
      [Range.INNOVATION]: 'Лучшие эксплуатационные характеристики'
    }
  },
  optimize: {
    title: 'Оптимизация',
    trolleys: {
      [Trolley.MAIN]: 'Эндоскопическая стойка',
      [Trolley.GHOST]: 'Дополнительные компоненты',
      [Trolley.ESG]: 'ВЧ-генераторы'
    },
    trolleyTitles: {
      [Trolley.MAIN]: 'Ваша эндоскопическая система',
      [Trolley.GHOST]: `@:optimize.trolleys.${Trolley.GHOST}`,
      [Trolley.ESG]: `@:optimize.trolleys.${Trolley.ESG}`
    },
    empty: 'Пусто',
    addComponent: 'Добавить компоненты',
    scopeGuide: 'ScopeGuide',
    zones: {
      M0: 'Мониторы',
      M1: 'Искусственный интеллект',
      M2: 'Видеопроцессоры',
      M3: 'Ультразвуковой процессор',
      M4: 'Помпы доп. подачи воды',
      M5: 'Инсуффлятор CO²',
      M6: 'ВЧ-генераторы',
      M7: 'Эндоскопы'
    },
    selectedCounted: '{count} выбрано',
    viewESG: 'ВЧ-генераторы',
    ghostDescription:
      'Если на эндоскопической системе не помещаются все выбранные компоненты, то часть оборудования будет отображена в этом разделе (только в целях демонстрации):',
    esgDescription:
      'ESG-300 поставляется в комплекте с тележкой TC-E300 (и дополнительным аргоноплазменным коагулятором).',
    supportHintMedicalSpeciality: 'только {supported}'
  },
  changeArticle: {
    subline: 'Ваша индивидуальная конфигурация',
    headline: 'Изменить конфигурацию',
    showDetails: 'Показать детали',
    scopesSelectedCounted: '{count} выбран эндоскоп |{count} эндоскопов выбрано',
    currentlySelected: 'Сейчас выбрано:',
    productCode: 'Код продукта',
    hasRequirementsInfo: 'Выберите одну из процедур из списка ниже.',
    isRequirementInfo: 'Удалите выбранные процедуры:',
    technicalSpecifications: 'Технические характеристики',
    noArticlesAvailable: 'Нет оборудования, совместимого с выбранным видеопроцессором.',
    noScopesAvailable: 'Нет оборудования, совместимого с выбранным видеопроцессором.',
    scopeGuideSubline: 'Система позиционирования колоноскопа ScopeGuide',
    scopeGuideIntro: 'Принцип действия системы позиционирования колоноскопа ScopeGuide',
    scopeGuideSummary:
      'Система ScopeGuide формирует 3D-изображение положения и конфигурации колоноскопа в толстой кишке в реальном времени.',
    scopeGuideDescription:
      'Электромагнитная катушка, расположенная по всей длине вводимой части эндоскопа, генерирует импульсное магнитное поле низкой интенсивности, сигналы которого воспринимаются приемной антенной. <br /> Магнитные импульсы используются для расчета точного положения и ориентации вводимой части колоноскопа и построения трехмерного изображения на мониторе.',
    scopeGuideIncludedItems: 'Включает следующие компоненты:',
    notVisualized: '*Не входит в конфигурацию с тележкой.'
  },
  scopeFilter: {
    area: {
      label: 'Область',
      items: {
        [GIProcedureArea.UPPER_GI]: 'Верхние отделы желудочно-кишечного тракта',
        [GIProcedureArea.LOWER_GI]: 'Нижние отделы желудочно-кишечного тракта',
        [GIProcedureArea.ERCP]: 'ЭРХПГ',
        [GIProcedureArea.ENTEROSCOPY]: 'Энтероскопия',
        [GIProcedureArea.ULTRASOUND]: 'УЗИ'
      }
    },
    length: {
      label: 'Длина',
      items: {
        [LowerGILength.INTERMEDIATE]: 'Средний',
        [LowerGILength.LONG]: 'Длинный'
      }
    },
    respiratoryType: {
      label: 'Тип эндоскопа',
      items: {
        [RespiratoryType.BRONCHOSCOPE]: 'Бронхоскоп',
        [RespiratoryType.MINIPROBE]: 'Минизонд',
        [RespiratoryType.PLEURASCOPE]: 'Плевроскоп',
        [RespiratoryType.ULTRASOUND]: 'УЗИ'
      }
    },
    features: {
      label: 'Характеристики',
      items: {
        [Feature.WATER_JET]: 'Дополнительная подача воды',
        [Feature.HQ]: 'Совместимость с ВЧ-инструментами',
        [Feature.TRANSNASAL]: 'Трансназальный',
        [Feature.PCF]: 'PCF'
      }
    },
    series: {
      label: 'Серия эндоскопа'
    }
  },
  accessories: {
    title: 'Принадлежности',
    headline:
      'Мы рекомендуем приобрести следующие принадлежности для обеспечения максимальной функциональности эндоскопической системы'
  },
  request: {
    title: 'Отправка запроса',
    subline: 'Выбранные продукты',
    headline: 'Отправьте запрос и мы свяжемся с вами, чтобы обсудить индивидуальное предложение',
    areaTrolley: 'Эндоскопическая система',
    areaAccessories: 'Принадлежности'
  },
  success: {
    title: 'Запрос отправлен',
    headline: 'Благодарим за обращение.',
    subline:
      'Ваш запрос на уточнение конфигурации эндоскопической системы успешно отправлен. Обратите внимание, что получение письма может занять до пяти минут.<br /> Мы свяжемся с вами в скором времени.',
    secondSubline: 'Дополнительные сведения о EVIS X1',
    buttonNew: 'Запустить новую конфигурацию',
    buttonDetail: 'Узнать больше',
    detailAssetPath: '/assets/successScreen_bottom_en.png',
    detailLink:
      'https://www.olympus-europa.com/medical/en/Products-and-Solutions/Products/Product/EVIS-X1.html'
  },
  contact: {
    subline: 'Обратная связь',
    headline: 'Получить поддержку',
    info: 'Нужна помощь в конфигурации вашей эндоскопической системы? Обратитесь к нам за помощью и мы свяжемся с вами в ближайшее время!',
    success:
      'Ваш запрос на уточнение конфигурации эндоскопической системы успешно отправлен. <br />Мы свяжемся с вами в скором времени.',
    customerQuestionHeadline: 'Запрос',
    backToConfiguration: 'Назад к конфигурации'
  },
  saveConfiguration: {
    title: 'Продолжить позже',
    subtitle: 'Сохранить на потом',
    subline:
      'Введите свой адрес электронной почты, и мы вышлем вам ссылку для возобновления конфигурации.',
    sendLink: 'Пришлите мне ссылку',
    copyLinkToClipboard: 'Скопируйте эту ссылку в буфер обмена',
    emailHeadline: 'Какой у вас электронный адрес?',
    writeClipboardSuccess: 'Копируется в буфер обмена',
    writeClipboardFailed: 'Копирование в буфер обмена не удалось',
    successHeadline: 'Конфигурация сохранена',
    successSubline:
      'Ваша конфигурация успешно сохранена.<br /> Продолжите в любое время по ссылке ниже.'
  },
  footerUrlsLabel: {
    imprint: 'Издательство',
    termsOfUse: 'Условия использования',
    privacyNotice: 'Kонфиденциальность',
    cookies: 'Cookie-файлы',
    cookiesSettings: 'Настройки файлов cookie',
    socialMedia: 'Cоциальные сети'
  },
  contactForm: {
    country: 'Страна',
    countryHeadline: 'Выберите вашу страну',
    customerName: 'Имя и фамилия',
    customerNameHeadline: 'Как к вам обращаться?',
    email: 'Адрес эл. почты',
    emailHeadline: 'Укажите ваш адрес эл. почты',
    customerPhoneNumber: 'Номер телефона',
    customerPhoneNumberHeadline: 'Укажите ваш номер телефона',
    customerCompany: 'Ваша больница/мед. учреждение',
    customerCompanyHeadline: 'В какой больнице/медицинском учреждении вы работаете?',
    customerQuestion: 'Ваш комментарий',
    customerQuestionHeadline: 'Хотите ли вы рассказать нам что-то еще о себе',
    privacy:
      'В компании Olympus с уважением относятся к вашей личной информации. Сведения о том, как мы обрабатываем личную информацию для ответа на заявку, можно посмотреть по ссылке',
    checkboxRequired: '*Обязательно'
  },
  actions: {
    back: 'Назад',
    confirm: 'Подтвердить',
    continue: 'Продолжить',
    no: 'Нет',
    save: 'Сохранить',
    select: 'Выбрать',
    selectNothing: 'ничего не выбирать',
    selected: 'Выбрано',
    selectedNothing: 'Ничего не выбрано',
    sendRequest: 'Отправить запрос',
    submit: 'Отправить',
    yes: 'Да'
  },
  error: {
    alert: 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
    title: 'Что-то пошло не так',
    subline: 'Благодарим за интерес, проявленный к оборудованию и инструментам компании Olympus!',
    info: 'К сожалению, возникла ошибка. Подождите несколько секунд и повторите последнее действие или обновите страницу.',
    contact: 'Свяжитесь с нашей {0}.',
    customerService: 'Клиентской службой',
    refresh: 'Обновить страницу',
    retry: 'Клиентской службой'
  },
  formData: {
    customerName: 'Name',
    customerCompany: '@:contactForm.customerCompany',
    country: 'Country',
    email: 'Email',
    customerPhoneNumber: 'Phone',
    customerQuestion: 'Message',
    optional: 'необязательно'
  },
  countryOptions: [
    { label: 'Албания', value: 'AL' },
    { label: 'Алжир', value: 'DZ' },
    { label: 'Ангола', value: 'AO' },
    { label: 'Армения', value: 'AM' },
    { label: 'Австрия', value: 'AT' },
    { label: 'Азербайджан', value: 'AZ' },
    { label: 'Бахрейн', value: 'BH' },
    { label: 'Беларусь', value: 'BY' },
    { label: 'Бельгия', value: 'BE' },
    { label: 'Босния и Герцеговина', value: 'BA' },
    { label: 'Ботсвана', value: 'BW' },
    { label: 'Болгария', value: 'BG' },
    { label: 'Камерун', value: 'CM' },
    { label: 'Хорватия', value: 'HR' },
    { label: 'Кипр', value: 'CY' },
    { label: 'Чешская Республика', value: 'CZ' },
    { label: 'Дания', value: 'DK' },
    { label: 'Джибути', value: 'DJ' },
    { label: 'Египет', value: 'EG' },
    { label: 'Эстония', value: 'EE' },
    { label: 'Эфиопия', value: 'ET' },
    { label: 'Финляндия', value: 'FI' },
    { label: 'Франция', value: 'FR' },
    { label: 'Грузия', value: 'GE' },
    { label: 'Германия', value: 'DE' },
    { label: 'Гана', value: 'GH' },
    { label: 'Греция', value: 'GR' },
    { label: 'Венгрия', value: 'HU' },
    { label: 'Исландия', value: 'IS' },
    { label: 'Иран', value: 'IR' },
    { label: 'Ирак', value: 'IQ' },
    { label: 'Израиль', value: 'IL' },
    { label: 'Италия', value: 'IT' },
    { label: "Кот-д'Ивуар", value: 'CI' },
    { label: 'Иордания', value: 'JO' },
    { label: 'Казахстан', value: 'KZ' },
    { label: 'Кения', value: 'KE' },
    { label: 'Кувейт', value: 'KW' },
    { label: 'Киргизия', value: 'KG' },
    { label: 'Латвия', value: 'LV' },
    { label: 'Ливан', value: 'LB' },
    { label: 'Лесото', value: 'LS' },
    { label: 'Ливия', value: 'LY' },
    { label: 'Литва', value: 'LT' },
    { label: 'Македония', value: 'MK' },
    { label: 'Малави', value: 'MW' },
    { label: 'Мавритания', value: 'MR' },
    { label: 'Маврикий', value: 'MU' },
    { label: 'Черногория', value: 'ME' },
    { label: 'Марокко', value: 'MA' },
    { label: 'Мозамбик', value: 'MZ' },
    { label: 'Намибия', value: 'NA' },
    { label: 'Нидерланды', value: 'NL' },
    { label: 'Нигерия', value: 'NG' },
    { label: 'Норвегия', value: 'NO' },
    { label: 'Оман', value: 'OM' },
    { label: 'Палестина', value: 'PS' },
    { label: 'Польша', value: 'PL' },
    { label: 'Португалия', value: 'PT' },
    { label: 'Катар', value: 'QA' },
    { label: 'Республика Конго', value: 'CG' },
    { label: 'Румыния', value: 'RO' },
    { label: 'Россия', value: 'RU' },
    { label: 'Руанда', value: 'RW' },
    { label: 'Саудовская Аравия', value: 'SA' },
    { label: 'Сенегал', value: 'SN' },
    { label: 'Сербия/Черногория', value: 'RS' },
    { label: 'Сейшельские острова', value: 'SC' },
    { label: 'Словакия', value: 'SK' },
    { label: 'Словения', value: 'SI' },
    { label: 'Сомали', value: 'SO' },
    { label: 'Южная Африка', value: 'ZA' },
    { label: 'южный Судан', value: 'SS' },
    { label: 'Испания', value: 'ES' },
    { label: 'Судан', value: 'SD' },
    { label: 'Свазиленд', value: 'SZ' },
    { label: 'Швеция', value: 'SE' },
    { label: 'Швейцария', value: 'CH' },
    { label: 'Сирия', value: 'SY' },
    { label: 'Танзания', value: 'TZ' },
    { label: 'Того', value: 'TG' },
    { label: 'Тунис', value: 'TN' },
    { label: 'Турция', value: 'TC' },
    { label: 'Великобритания/Ирландия', value: 'GB' },
    { label: 'Уганда', value: 'UG' },
    { label: 'Украина', value: 'UA' },
    { label: 'Объединенные Арабские Эмираты', value: 'AE' },
    { label: 'Узбекистан', value: 'UZ' },
    { label: 'Замбия', value: 'ZM' },
    { label: 'Зимбабве', value: 'ZW' }
  ]
};
